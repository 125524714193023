import { faArchway, faBars, faBuilding, faIcons, faPersonDress, faShield, faTrain, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faMasksTheater } from "@fortawesome/free-solid-svg-icons/faMasksTheater";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export default function Sidebar(){

    const [open, setOpen] = useState(false)


    return(
        <div className={`s-container ${open ? "isOpen" : "isClose"}`}>
            <FontAwesomeIcon 
                icon={faBars} 
                onClick={() => setOpen(!open)}
                className="bars"
            />
            <div className="s-list">
                <ul>
                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faUtensils}/>
                        <p>Catering</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faTrain}/>
                        <p>Transportation</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faMasksTheater}/>
                        <p>Entertainment</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faArchway}/>
                        <p>Stage Decor</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faBuilding}/>
                        <p>Convention Center</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faPersonDress}/>
                        <p>Bridal and Groom Wear</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faIcons}/>
                        <p>Media</p>
                    </div>

                    <div className="list">
                        <FontAwesomeIcon className="icon" icon={faShield}/>
                        <p>Security</p>
                    </div>
                </ul>
            </div>
        </div>
    )
}