import { faFilm, faGrip, faImage, faLocationDot, faPhotoFilm, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import propic from "../../../images/main.jpg"

export default function Feeds(){

    const[imgURL, setImgURL] = useState('')

    const fileUploadRef  = useRef()

    const imageUpload =  (e) =>{
        e.preventDefault()
        fileUploadRef.current.click()

    } 

    const uploadImage =  () => {
        const uploadedFile  = fileUploadRef.current.files[0]
        const cachedURL = URL.createObjectURL(uploadedFile)
        setImgURL(cachedURL)
    }

    const[desc,setDesc]  = useState("")
    const[prev,setPrev] = useState(true)
    const[pvExpand,setPvExpand] = useState(false)
    
    return(
        <div className="dcd-main">
            {prev ?  
            <form action="">
                <textarea 
                    type="text" 
                    className="desc" 
                    placeholder="Describe Your Event" 
                    onChange={(e)=> setDesc(e.target.value)}
                />
                <ul>
                    <li>
                        <div className="phvid" onClick={()=>  setPvExpand(true)} onMouseLeave={()=> setPvExpand(false)}>
                            <FontAwesomeIcon className="icon" icon={faPhotoFilm}/>
                            <label htmlFor="">Photo/Video</label>
                            {pvExpand && 
                            <div className="menu">
                                <div className="file">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faImage}/>
                                        <label htmlFor="">Photo</label>
                                    </div>
                                    <input type="file" accept="image/*" ref={fileUploadRef} onChange={uploadImage}/>
                                </div>
                                <hr />
                                <div className="file">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faFilm}/>
                                        <label htmlFor="">Video</label>
                                    </div>
                                    <input type="file" accept="video/*"/>
                                </div>
                            </div>
                            }
                        </div>
                    </li>
                    <hr />
                    <li>
                        <div className="categ">
                            <li><FontAwesomeIcon className="icon" icon={faGrip}/></li>
                            <li>
                                <select name="" id="">
                                    <option  disabled selected value>Select a Category</option>
                                    <option>Catering</option>
                                    <option>Transportation</option>
                                    <option>Entertainment </option>
                                    <option>Stage Decor</option>
                                    <option>Convention centre</option>
                                    <option>Bridal and Groom wear</option>
                                    <option>Media </option>
                                    <option>Security</option>
                                </select>
                            </li>
                        </div>
                    </li>
                    <hr />
                    <li>
                        <div className="loc">
                            <FontAwesomeIcon className="icon" icon={faLocationDot}/>
                            <button>Add Location</button>
                        </div>
                    </li>
                    <hr />
                </ul>
                <div className="btns">
                    <button className="cancel">Cancel</button>
                    <button type="submit" onClick={()=> setPrev(false)} className="p-btn">Preview</button>
                </div> 
            </form>
            :
            <div className="preview">
                <div className="card">
                    <div className="top">
                        <img src={propic} alt="" />
                        <h2>Company Name</h2>
                    </div>
                    <div className="con">
                        <img className="mImg" src={imgURL} alt="" />
                        <p>{desc}</p>
                    </div>
                </div>
            </div>
}
        </div>
    )
}