import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import SideChats from "./SideChats";
import Cards from "./Cards";

export default function FrontPage(){
    return(
        <div className="front-main">
            <Navbar/>
            <div className='flex'>
                <Sidebar/>
                <Cards/>
                <SideChats/>
            </div>
        </div>
    )
}