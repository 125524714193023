import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { text } from "@fortawesome/fontawesome-svg-core";

export default function LoginPage(){
    const [page, setPage] = useState(true)
    const [show, setShow] = useState(true)
    const [showS, setShowS] = useState(true)
    const [showR, setShowR] = useState(true)
    
    function showP(e){
        e.preventDefault()
        setShow(!show)
    }

    function showSe(e){
        e.preventDefault()
        setShowS(!showS)
    }

    function showRe(e){
        e.preventDefault()
        setShowR(!showR)
    }

    // LoginPage Credentials
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)

    const handleLogin = (e) =>{
        console.log("Form submitted")
        e.preventDefault()
        axios.post('https://hapvent.backend.webwic.com/login',{
            email:username,
            password:password
        },{
            headers:{
                'Content-Type' : 'application/json'
            }
        })
        .then(res=> {
            console.log(res.data)
            console.log("Success")
        })
        .catch(er =>{
            console.error("Error logging in!",er)
        })
    }

    // SignUpPage Credentails
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [sPassword, setSPassword] = useState('')
    const [conPassword, setConPassword] = useState('')

    // Window resizer

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = (e) => {
            e.preventDefault()
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);    
        return () => window.removeEventListener('resize', handleResize);
    },[]);

    return(
        <div className="l-main">
            {width <= 1200 ? 
            <div className="l-container">
                {page ?
                <div className="login">
                    <div className="left">
                        <h1>Sign In</h1>
                        <form id="form" onSubmit={handleLogin}>
                            <div className="form-ip">
                                <label htmlFor="email">Email/Username</label>
                                <input 
                                    value={username} 
                                    onChange={e=>setUsername(e.target.value)} 
                                    type="email" 
                                    id="email" 
                                    required
                                />
                            </div>
                            <div className="form-ip">
                                <label htmlFor="password">Password</label>
                                <div className="pass">
                                    <input 
                                        type={show ? "password" : "text"} 
                                        id="password"
                                        value={password}
                                        onChange={e=>setPassword(e.target.value)}
                                        minLength={5}
                                        maxLength={20} 
                                        required
                                    />
                                    <FontAwesomeIcon 
                                        icon={ show ? faEye : faEyeSlash} 
                                        className="show-p" 
                                        onClick={showP}
                                    />
                                </div>
                                </div>
                            <div className="check">
                                <div className="rem">
                                    <label htmlFor="checkbox">Remember me</label>
                                    <input 
                                        type="checkbox" 
                                        onClick={()=> setRemember(!remember)}
                                        id="checkbox" 
                                    />
                                </div>
                                <a href="www">Forgot Password</a>
                            </div>
                            <button className="submit-btn" type="submit">Login</button>
                        </form>
                        <br />
                        <p>Don't have an account? <a onClick={() => setPage(!page)}>Sign In</a></p>
                    </div>
                </div>
                :
                <div className="sign">
                    <div className="left">
                        <h1>Create a new account</h1>
                        <form id="form">
                            <div className="form-ip">
                                <label htmlFor="name">Enter your full name</label>
                                <input 
                                    type="text" 
                                    value={name}
                                    onChange={e=>setName(e.target.value)}
                                    required 
                                />
                            </div>
                            <div className="form-ip">
                                <label htmlFor="email">Enter email address</label>
                                <input 
                                    type="email"
                                    value={email}
                                    onChange={e=>setEmail(e.target.value)}
                                    required 
                                />
                            </div>
                            <div className="form-ip">
                                <label htmlFor="password">Enter password</label>
                                <div className="pass">
                                    <input 
                                        type={showS ? "password" : "text"} 
                                        value={sPassword}
                                        onChange={e=>setSPassword(e.target.value)}
                                        required
                                    />
                                    <FontAwesomeIcon 
                                        icon={ showS ? faEye : faEyeSlash} 
                                        className="show-p" 
                                        onClick={showSe}
                                    />
                                </div>
                            </div>
                            <div className="form-ip">
                                <label htmlFor="email">Re-enter password</label>
                                <div className="pass">
                                    <input 
                                        type={showR ? "password" : "text"} 
                                        value={conPassword}
                                        onChange={e=>setConPassword(e.target.value)}
                                        required
                                    />
                                    <FontAwesomeIcon 
                                        icon={ showR ? faEye : faEyeSlash} 
                                        className="show-p" 
                                        onClick={showRe}
                                    />
                                </div>
                            </div>
                            <button className="submit-btn" type="submit">Sign Up</button>
                        </form>
                        <br />
                        <p>Already have an account? <a onClick={() => setPage(!page)}>Log In</a></p>
                    </div>
                </div>
                }
            </div>
            :
            <div className="l-container">
                {page && <div className="login">
                    <div className="left">
                        <h1>Sign In</h1>
                        <form id="form" onSubmit={handleLogin}>
                            <div className="form-ip">
                                <label htmlFor="email">Email/Username</label>
                                <input 
                                    value={username} 
                                    onChange={e=>setUsername(e.target.value)} 
                                    type="email" 
                                    id="email" 
                                    required
                                />
                            </div>
                            <div className="form-ip">
                                <label htmlFor="password">Password</label>
                                <div className="pass">
                                    <input 
                                        type={show ? "password" : "text"} 
                                        id="password"
                                        value={password}
                                        onChange={e=>setPassword(e.target.value)}
                                        minLength={5}
                                        maxLength={20} 
                                        required
                                    />
                                    <FontAwesomeIcon 
                                        icon={ show ? faEye : faEyeSlash} 
                                        className="show-p" 
                                        onClick={showP}
                                    />
                                </div>
                                </div>
                            <div className="check">
                                <div className="rem">
                                    <label htmlFor="checkbox">Remember me</label>
                                    <input 
                                        type="checkbox" 
                                        onClick={()=> setRemember(!remember)}
                                        id="checkbox" 
                                    />
                                </div>
                                <a href="www">Forgot Password</a>
                            </div>
                            <button className="submit-btn" type="submit">Login</button>
                        </form>
                    </div>
                    <div className="right">
                        <h1>Welcome to Login</h1>
                        <h3>Don't have an account?</h3>
                        <button onClick={() => setPage(false)}>Sign Up</button>
                    </div>
                </div>}

                {!page && <div className="sign">
                    <div className="left">
                        <h1>Create a new account</h1>
                        <form id="form">
                            <div className="form-ip">
                                <label htmlFor="name">Enter your full name</label>
                                <input 
                                    type="text" 
                                    value={name}
                                    onChange={e=>setName(e.target.value)}
                                    required 
                                />
                            </div>
                            <div className="form-ip">
                                <label htmlFor="email">Enter email address</label>
                                <input 
                                    type="email"
                                    value={email}
                                    onChange={e=>setEmail(e.target.value)}
                                    required 
                                />
                            </div>
                            <div className="form-ip">
                                <label htmlFor="password">Enter password</label>
                                <div className="pass">
                                    <input 
                                        type={showS ? "password" : "text"} 
                                        value={sPassword}
                                        onChange={e=>setSPassword(e.target.value)}
                                        required
                                    />
                                    <FontAwesomeIcon 
                                        icon={ showS ? faEye : faEyeSlash} 
                                        className="show-p" 
                                        onClick={showSe}
                                    />
                                </div>
                            </div>
                            <div className="form-ip">
                                <label htmlFor="email">Re-enter password</label>
                                <div className="pass">
                                    <input 
                                        type={showR ? "password" : "text"} 
                                        value={conPassword}
                                        onChange={e=>setConPassword(e.target.value)}
                                        required
                                    />
                                    <FontAwesomeIcon 
                                        icon={ showR ? faEye : faEyeSlash} 
                                        className="show-p" 
                                        onClick={showRe}
                                    />
                                </div>
                            </div>
                            <button className="submit-btn" type="submit">Sign Up</button>
                        </form>
                    </div>
                    <div className="right">
                        <h1>Already have an account?</h1>
                        <button onClick={() => setPage(true)}>Login</button>
                    </div>
                </div>}
            </div>
            }
        </div>
            
    )
}