export class ProductService {
    getProductsSmall() {
      const data = [
        { id: '1000', name: 'Bamboo Watch', description: 'A beautiful bamboo watch.', price: 65, inventoryStatus: 'INSTOCK', rating: 5, image: 'bamboo-watch.jpg' },
        { id: '1001', name: 'Black Watch', description: 'A stylish black watch.', price: 72, inventoryStatus: 'OUTOFSTOCK', rating: 4, image: 'black-watch.jpg' },
        { id: '1002', name: 'Blue Band', description: 'A cool blue band.', price: 79, inventoryStatus: 'LOWSTOCK', rating: 3, image: 'blue-band.jpg' },
        { id: '1003', name: 'Red Band', description: 'A vibrant red band.', price: 80, inventoryStatus: 'INSTOCK', rating: 5, image: 'red-band.jpg' },
        { id: '1004', name: 'Green Watch', description: 'A sleek green watch.', price: 90, inventoryStatus: 'OUTOFSTOCK', rating: 2, image: 'green-watch.jpg' },
        { id: '1005', name: 'Yellow Band', description: 'A bright yellow band.', price: 85, inventoryStatus: 'LOWSTOCK', rating: 4, image: 'yellow-band.jpg' },
        { id: '1006', name: 'Purple Watch', description: 'A sophisticated purple watch.', price: 95, inventoryStatus: 'INSTOCK', rating: 5, image: 'purple-watch.jpg' },
        { id: '1007', name: 'Orange Band', description: 'A lively orange band.', price: 75, inventoryStatus: 'INSTOCK', rating: 3, image: 'orange-band.jpg' }
      ];
      return Promise.resolve(data);
    }
  }
  