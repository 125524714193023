import { faLocationDot, faMagnifyingGlass, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStumbleupon } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import React from "react";

export default function Navbar(){
    return(
        <div className="nav-container">
            <div className="nav-l">
                <div className="logo">
                    <FontAwesomeIcon icon={faStumbleupon}bounce className="icon"/>
                </div>
                <div className="location">
                    <FontAwesomeIcon icon={faLocationDot} className="loc-icon"/>
                    <a href="www">Fort Kochi</a>
                </div>
            </div>
            <div className="nav-c">
                <div className="search">
                    <input type="text" placeholder="Search" />
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="icon"/>

                </div>
            </div>
            <div className="nav-r">
                <NavLink to='MyEvents'><button className="vendor-btn">I'm a vendor</button></NavLink>
                <NavLink to='/LoginPage'><button className="login-btn">Login</button></NavLink>
                <NavLink to='/Dashboard'><FontAwesomeIcon icon={faUser} className="profile"/></NavLink>
            </div>


        </div>
    )
}