import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { ProductService } from './ProductService';
import Cards from '../../Cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faClone, faGripHorizontal } from '@fortawesome/free-solid-svg-icons';

export default function Feeds() {
  const [products, setProducts] = useState([]);

  const responsiveOptions = [
    { breakpoint: '1400px', numVisible: 2, numScroll: 1 },
    { breakpoint: '1199px', numVisible: 3, numScroll: 1 },
    { breakpoint: '767px', numVisible: 2, numScroll: 1 },
    { breakpoint: '575px', numVisible: 1, numScroll: 1 }
  ];

  useEffect(() => {
    const productService = new ProductService();
    productService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
  }, []);

  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
            <Cards/>
        </div>
      </div>
    );
  };

  const[openMenu,setOpenMenu] = useState(false)
  const[view,setView] = useState('grid')

  return (
    <div className="df-main">
        <div className={`dtop ${openMenu ? "dopen" : ""}`}>
            <div className="tm" onClick={() => setOpenMenu(!openMenu)}> 
              <ul>
                <li><p>View</p></li>
                <li><FontAwesomeIcon icon={faChevronDown} className='a-icon'/></li>
              </ul>
            </div>
            {openMenu && <div className="dopenM">
                <div className="tm" onClick={() => setView('grid')}>
                    <ul>
                        <li> <FontAwesomeIcon icon={faGripHorizontal}/> </li>
                        <li><p>Grid</p></li>
                    </ul>
                </div>
                <div className="tm" onClick={() => setView('menu')}>
                    <ul>
                        <li> <FontAwesomeIcon icon={faClone}/> </li>
                        <li><p>Menu</p></li>
                    </ul>
                </div>
            </div>}
        </div>
        {view === 'menu' ?  <div className="menu">
            <Carousel value={products} numScroll={1} numVisible={2} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
        </div>
        :
        view ==='grid' ? 
        <div className="grid">
          <Cards/>
          <Cards/>
          <Cards/>
          <Cards/>
          <Cards/>

        </div>
        :
        "Error"
        }
    </div>
  );
}
