import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function Events(){
    return(
        <div className="ev-main">
            <div className="left">
                <div className="head">
                    <h1>EVENT NAME</h1>
                </div>
                <div className="quotations">
                    <div className="head">
                        <h1>Quotations</h1>
                    </div>
                    <div className="q-links">
                        <ul>
                            <li><a href="">NEW</a></li>
                            <li><a href="">ALL</a></li>
                            <li><a href="">HOLD</a></li>
                            <li><a href="">REJECTED</a></li>
                        </ul>
                    </div>
                    <div className="q-chat">
                        <div className="ch">

                        </div>
                    </div>
                </div>

            </div>
            <div className="right">
                <div className="top">
                    <img src="" alt="" />
                    <h1>Vendor Name</h1>
                </div>
                <div className="bottom">
                    <div className="btns">
                        <button>Cancel</button>
                        <button>Hold</button>
                        <button>Reply</button>
                    </div>
                    <div className="d-btn">
                        <button>Invoice</button>
                        <FontAwesomeIcon icon={faCircleArrowDown} className="d-icon"/>
                    </div>
                </div>

            </div>
            
        </div>
    )
}