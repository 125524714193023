import { faCirclePlus, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { NavLink } from "react-router-dom";

export default function MyEvents(){
    const events = [
        { id: 1, name: "Event 1", quote: "Value 1" },
        { id: 2, name: "Event 2", quote: "Value 2" },
        { id: 3, name: "Event 3", quote: "Value 3" },
        { id: 4, name: "Event 4", quote: "Value 4" },
        { id: 5, name: "Event 5", quote: "Value 5" },
        { id: 6, name: "Event 6", quote: "Value 6" },
        { id: 7, name: "Event 7", quote: "Value 7" },
        { id: 8, name: "Event 8", quote: "Value 8" },
        { id: 9, name: "Event 9", quote: "Value 9" },
      ];
      
    return(
        <div className="main">
            <div className="me-main">
                    <div className="me-top">
                        <h1>My Events</h1>
                        <div className="t-btns">
                            <div className="left">
                                <div className="search">
                                    <input type="text" placeholder="search" /> 
                                    <FontAwesomeIcon className="icon" icon={faMagnifyingGlass}/>                               
                                </div>
                                <select className="filter">
                                    <option>Filters</option>
                                    <option>Filters</option>
                                    <option>Filters</option>
                                </select>
                            </div>
                            <div className="right">
                                <NavLink to='/CreateEvent'><button>New Event</button></NavLink>
                                <AddCircleOutlineIcon className="add"/>
                            </div>
                        </div>
                    </div>

                    <div className="me-bottom">
                        <div className="me-table">
                            <table className="event-table">
                                <thead>
                                    <tr>
                                    <th>Sl.No</th>
                                    <th>Event Name</th>
                                    <th>Action Quote</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {events.map((event, index) => (
                                    <tr key={event.id} className={index % 2 === 0 ? "even" : "odd"}>
                                        <td>{index + 1}.</td>
                                        <td>
                                        <a href="#">{event.name}</a>
                                        </td>
                                        <td>{event.quote}</td>
                                        <td>
                                        <button className="edit-btn">
                                            <EditIcon />
                                        </button>
                                        <button className="delete-btn">
                                            <DeleteIcon />
                                        </button>
                                        <NavLink to='/Events'>
                                            <button className="view-btn">
                                                <VisibilityIcon />
                                            </button>
                                        </NavLink>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>
        </div>
    )
}