import { faCircle, faMagnifyingGlass, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import img from "../images/images.jpeg"
import Chats from "./Chats";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';


export default function SideChats(){
    const [open, isOpen] = useState(true)
    const [chat, setChat] = useState(true)
    
    return(
        <div className={`ch-container ${open ? "Open" : "Close"}`}>
             {/* <div className="ch-chats">
                 <ArrowBackIosNewOutlinedIcon onClick={() => (setChat(false))} className="backArrow"/>
                 <Chats/>
             </div> */}
            <div className="top">
                <div className="head">
                    <FontAwesomeIcon 
                        icon={faMessage} 
                        className="icon" 
                        onClick={() => isOpen(!open)}
                        />
                    <h2>Chats</h2>
                </div>
                <div className="search">
                    <input type="text" placeholder="Search" className="search"/>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="icon"/>
                </div>
                <div className="chats">
                    <div className="ch" onClick={() => (setChat(true))}>
                        <img src={img} alt="" />
                        <div className="name">
                            <p className="uname">FirstName LastName</p>
                            <p className="notif">+New Message</p>
                        </div>
                        <FontAwesomeIcon icon={faCircle} className="offline"/>
                    </div>
                    <div className="ch">
                        <img src={img} alt="" />
                        <div className="name">
                            <p className="uname">FirstName LastName</p>
                            <p className="notif">+New Message</p>
                        </div>
                        <FontAwesomeIcon icon={faCircle} className="online"/>
                    </div>
                    <div className="ch">
                        <img src={img} alt="" />
                        <div className="name">
                            <p className="uname">FirstName LastName</p>
                            <p className="notif">+New Message</p>
                        </div>
                        <FontAwesomeIcon icon={faCircle} className="offline"/>
                    </div>
                    <div className="ch">
                        <img src={img} alt="" />
                        <div className="name">
                            <p className="uname">FirstName LastName</p>
                            <p className="notif">+New Message</p>
                        </div>
                        <FontAwesomeIcon icon={faCircle} className="online"/>
                    </div>
                    <div className="ch">
                        <img src={img} alt="" />
                        <div className="name">
                            <p className="uname">FirstName LastName</p>
                            <p className="notif">+New Message</p>
                        </div>
                        <FontAwesomeIcon icon={faCircle} className="online"/>
                    </div>  
                </div>
            </div>
        </div>
        
    )
}