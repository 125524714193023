import { faBookmark, faEnvelope, faPaperPlane, faShareNodes, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import profile from "../images/images.jpeg"
import { useSwipeable } from 'react-swipeable';
import main from "../images/main.jpg"
import main2 from "../images/main2.jpg"
import main3 from "../images/main3.jpg"

export default function Cards(){
    const[currentIndex, setCurrentIndex] = useState(0);
    const images = [main,main2,main3]

    function leftImage(e){
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length) 
    }

    function rightImage(e){
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }

    const handlers = useSwipeable({
        onSwipedLeft: leftImage,
        onSwipedRight: rightImage,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
      });

    return(
        <div className="c-container">
            <div className="card">
                <div className="top">
                    <div className="left">
                        <img src={profile} />
                        <p className="us-name">Lisa Martinez</p>
                    </div>
                    <div className="right">
                        <FontAwesomeIcon icon={faEnvelope} className="env"/>
                    </div>
                </div>
                <div className="main" {...handlers}>
                    <FontAwesomeIcon className="btn-left" icon={faChevronLeft} onClick={leftImage}/>
                    <img src={images[currentIndex]} className="main" />
                    <FontAwesomeIcon className="btn-right" icon={faChevronRight} onClick={rightImage}/>
                </div>
                <div className="bottom">
                    <div className="left">
                        <FontAwesomeIcon icon={faPaperPlane} className="l-icon"/>
                        <FontAwesomeIcon icon={faShareNodes} className="l-icon"/>
                    </div>

                    <div className="right">
                        <FontAwesomeIcon icon={faBookmark} className="r-icon"/>
                    </div>
                </div>
                <p>Hey everyone! 🎉 I’m hosting a summer BBQ at my place this Saturday afternoon, and I’d love for you all to join! We’ll kick things off around 2 PM, and it’s going to be a blast with great food, drinks, and plenty of fun.</p>
            </div>
            <div className="card">
                <div className="top">
                    <div className="left">
                        <img src={profile} />
                        <p className="us-name">Lisa Martinez</p>
                    </div>
                    <div className="right">
                        <FontAwesomeIcon icon={faEnvelope} className="env"/>
                    </div>
                </div>
                <div className="main" {...handlers}>
                    <FontAwesomeIcon className="btn-left" icon={faChevronLeft} onClick={leftImage}/>
                    <img src={images[currentIndex]} className="main" />
                    <FontAwesomeIcon className="btn-right" icon={faChevronRight} onClick={rightImage}/>
                </div>
                <div className="bottom">
                    <div className="left">
                        <FontAwesomeIcon icon={faPaperPlane} className="l-icon"/>
                        <FontAwesomeIcon icon={faShareNodes} className="l-icon"/>
                    </div>

                    <div className="right">
                        <FontAwesomeIcon icon={faBookmark} className="r-icon"/>
                    </div>
                </div>
                <p>Hey everyone! 🎉 I’m hosting a summer BBQ at my place this Saturday afternoon, and I’d love for you all to join! We’ll kick things off around 2 PM, and it’s going to be a blast with great food, drinks, and plenty of fun.</p>
            </div>
        </div>
    )
}