import React, { useState } from "react";
import clogo from "../../../images/clogo.png"
import Feeds from "./Feeds";
import CardDetails from "./CardDetails";
import Profile from "./Profile";

export default function Dashboard(){
    const[showCon,setShowCon] = useState("Feed")
    const [cname,setCName] = useState('Company Name')

    return(
        <div className="d-main">
            <div className="d-top">
                <img src={clogo} alt="" />
                <h2>{cname}</h2>
            </div>
            <div className="d-nav">
                <ul>
                    <li className={`${showCon === "Feed" ? "active" : ""}`} onClick={() => setShowCon("Feed")}><a href=""></a>Feed</li>
                    <li className={`${showCon === "Card" ? "active" : ""}`} onClick={() => setShowCon("Card")}><a href=""></a>Card Details</li>
                    <li className={`${showCon === "Profile" ? "active" : ""}`} onClick={() => setShowCon("Profile")}><a href=""></a>Profile</li>
                </ul>
            </div>
            <div className="content">
                {showCon ==="Feed" ? <Feeds/> 
                 : 
                 showCon ==="Card" ? <CardDetails/> 
                 : 
                 showCon === "Profile" ? <Profile cname={cname} setCName={setCName}/> : ""}
            </div>
        </div>
    )
}