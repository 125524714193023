import './App.css';
import { BrowserRouter, Routes,Route } from 'react-router-dom';
import FrontPage from './components/FrontPage';
import LoginPage from './components/LoginPage'
import MyEvents from './components/MyEvents';
import CreateEvent from './components/CreateEvent'
import Events from './components/Events'
import Dashboard from './components/Vendor/Dashboard/Dashboard'

function App() {
  return (
     <div className="App">
      <Routes>
        <Route element={<FrontPage/>} path='/'/>
        <Route element={<LoginPage/>} path='/LoginPage'/>
        <Route element={<MyEvents/>} path='/MyEvents'/>
        <Route element={<CreateEvent/>} path='/CreateEvent'/>
        <Route element={<Events/>} path='/Events'/>
        <Route element={<Dashboard/>} path='/Dashboard'/>
      </Routes>
     </div>
  );
}

export default App;
