import React from "react";

export default function CreateEvent(){
    return(
        <div className="cr-main">
            <form action="">
                <div className="content">
                    <h1>Create a New Event</h1>
                    <input type="text" required placeholder="Event Name"/>
                    <input type="text" required placeholder="Location"/>
                    <input type="text" required placeholder="Details about the event" />
                    <div className="end">
                        <div className="label">
                            <label htmlFor="">Category</label>
                            <select required name="" id="" placeholder="Category">
                                <option>Catering</option>
                                <option>Transportation</option>
                                <option>Entertainment </option>
                                <option>Stage Decor</option>
                                <option>Convention centre</option>
                                <option>Bridal and Groom wear</option>
                                <option>Media </option>
                                <option>Security</option>
                            </select>
                        </div>
                        <div className="label">
                            <label htmlFor="">Event Date</label>
                            <input required type="date" placeholder="Event Date" />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="content">
                    <h2>Additional Details</h2>
                    <input required type="number" placeholder="Expected Crowd"/>
                    <input required type="text" placeholder="Additional Details" className="big" />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>

    )
}