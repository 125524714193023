import { faFacebook, faInstagram, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export default function Profile(props){

    return(
        <div className="dp-main">
            <form>
                <div className="top">
                    <div className="left">
                        <input type="text" placeholder="Company  Name" value={props.cname} onChange={(e)=>props.setCName(e.target.value)}/>
                        <textarea placeholder="Description"></textarea>
                    </div>
                    <div className="right">
                        <div className="file">
                            <div className="icon">
                                <label htmlFor="">Upload An Image</label>
                                <FontAwesomeIcon className="imgIcon" icon={faImage}/>
                            </div>
                            <input type="file" accept="image/*" />
                        </div>
                        <input type="number" placeholder="GST Number" />
                        <input type="text" placeholder="Address" />
                    </div>
                </div>
                <hr />
                <div className="bottom">
                    <h3>Social Media Links</h3>
                    <div className="links">
                        <div className="link">
                            <FontAwesomeIcon icon={faInstagram}/>
                            <p>Instagram</p>
                        </div>
                        <div className="link">
                            <FontAwesomeIcon icon={faFacebook}/>
                            <p>Facebook</p>
                        </div>
                        <div className="link">
                            <FontAwesomeIcon icon={faYoutube}/>
                            <p>Youtube</p>
                        </div>
                        <div className="link">
                            <FontAwesomeIcon icon={faXTwitter}/>
                            <p>Twitter</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}